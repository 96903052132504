// Dashboard.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css';
import { Modal, Button } from 'react-bootstrap'; 
import { FaFolder, FaFolderOpen, FaFile, FaBars, FaTimes, FaCog } from 'react-icons/fa';
import axios from 'axios';
import { API_BASE_URL } from '../auth/api';
import { AVAILABLE_ICONS } from './AdminDashboard.jsx';
import { useNavigate } from 'react-router-dom';

function Dashboard({ onLogout, username, userRoles, isAdmin }) {
  const [menuItems, setMenuItems] = useState([]);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [currentPage, setCurrentPage] = useState("welcome");
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [expandedFolders, setExpandedFolders] = useState({});
  const navigate = useNavigate();

  const hasAccess = (menuRoles) => {
    // Nếu menu không có role nào, cho phép truy cập
    if (!menuRoles || menuRoles.length === 0) return true;
    
    // Nếu user có role 'all', cho phép truy cập tất cả
    if (userRoles.includes('all')) return true;

    // Kiểm tra từng role của menu
    return menuRoles.some(role => userRoles.includes(role));
  };
  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleLogoutClick = () => {
    setShowLogoutConfirm(true);
  };

  const handleLogoutConfirm = () => {
    setShowLogoutConfirm(false);
    onLogout();
  };

  const navigateTo = (page) => {
    setCurrentPage(page);
  };

  const toggleFolder = (folderName) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderName]: !prev[folderName],
    }));
  };

  useEffect(() => {
    fetchMenuItems();
  }, []);

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/menu`);
      setMenuItems(response.data);
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };

  const renderMenuItem = (item) => {
    // Nếu là folder, kiểm tra xem có item con nào có quyền truy cập không
    if (item.type === 'folder') {
      const folderChildren = menuItems.filter(child => child.parentId === item._id);
      const hasAccessibleChildren = folderChildren.some(child => {
        if (child.type === 'folder') {
          // Nếu là folder con, kiểm tra đệ quy
          const subChildren = menuItems.filter(sub => sub.parentId === child._id);
          return subChildren.some(subChild => hasAccess(subChild.roles));
        }
        // Nếu là report, kiểm tra quyền truy cập trực tiếp
        return hasAccess(child.roles);
      });
      
      // Nếu không có item con nào có quyền truy cập, không hiển thị folder
      if (!hasAccessibleChildren) return null;

      const icon = AVAILABLE_ICONS.find(i => i.value === item.icon);

      return (
        <li key={item._id} className="nav-item folder-item">
          <button
            className="nav-link folder-link"
            onClick={() => toggleFolder(item._id)}
          >
            <span className="icon">
              {expandedFolders[item._id] 
                ? (icon ? icon.label.split(' ')[0] : '📂') 
                : (icon ? icon.label.split(' ')[0] : '📁')}
            </span>
            <span className="text">{item.name}</span>
          </button>
          {expandedFolders[item._id] && folderChildren.length > 0 && (
            <ul className="nav flex-column ml-3">
              {folderChildren.map(child => renderMenuItem(child))}
            </ul>
          )}
        </li>
      );
    }

    // Nếu là report, kiểm tra quyền truy cập
    if (!hasAccess(item.roles)) return null;

    const icon = AVAILABLE_ICONS.find(i => i.value === item.icon);

    return (
      <li key={item._id} className="nav-item">
        <button
          className={`nav-link ${item.report?.isDisabled ? 'disabled-link' : ''}`}
          onClick={() => navigateTo(item.path || item.name)}
          disabled={item.report?.isDisabled}
        >
          <span className="icon">
            {icon ? icon.label.split(' ')[0] : '📄'}
          </span>
          <span className="text">
            {item.name}
            {item.report?.isDisabled && (
              <span className="no-data-indicator">(No data)</span>
            )}
          </span>
        </button>
      </li>
    );
  };

  const renderReport = (path) => {
    // Tìm menu item dựa trên path
    const menuItem = menuItems.find(item => item.path === path || item.name === path);
    
    if (!menuItem || !menuItem.report) return null;

    return (
      <div className="power-bi-container">
        <iframe
          title={menuItem.name}
          width="100%"
          height="600"
          src={menuItem.report.url}
          frameBorder="0"
          allowFullScreen={true}
        ></iframe>
      </div>
    );
  };

  const handleAdminClick = () => {
    navigate('/admin');
  };

  // Thêm hàm để lấy title của menu item
  const getMenuTitle = (path) => {
    const menuItem = menuItems.find(item => item.path === path || item.name === path);
    if (!menuItem) return '';

    // Nếu item có parent, hiển thị cả parent name
    if (menuItem.parentId) {
      const parent = menuItems.find(item => item._id === menuItem.parentId);
      if (parent) {
        return `${parent.name} - ${menuItem.name}`;
      }
    }
    return menuItem.name;
  };

  return (
    <div className="dashboard d-flex">
      <aside className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}>
        <div className="sidebar-header">
          <div className="logo-container">
            <h1 className="logo">REPORT</h1>
          </div>
        </div>
        <nav className="sidebar-nav">
          <ul className="nav flex-column">
            {menuItems
              .filter(item => !item.parentId)
              .map(item => renderMenuItem(item))}
          </ul>
        </nav>
      </aside>

      <main className={`main-content ${isSidebarCollapsed ? "sidebar-collapsed" : ""}`}>
        <header className="main-header">
          <button 
            className="menu-toggle"
            onClick={toggleSidebar}
            title={isSidebarCollapsed ? "Expand sidebar" : "Collapse sidebar"}
          >
            {isSidebarCollapsed ? <FaBars /> : <FaTimes />}
          </button>
          <h2 className="header-title">
            {currentPage === "welcome" ? "Welcome" : getMenuTitle(currentPage)}
          </h2>
          <div className="header-right">
            {isAdmin && (
              <button 
                className="admin-button"
                onClick={handleAdminClick}
              >
                <FaCog className="me-2" />
                Admin
              </button>
            )}
            <div className="user-menu">
              <span>Hello, {username.charAt(0).toUpperCase() + username.slice(1)}</span>
              <button 
                className="logout-button"
                onClick={handleLogoutClick}
              >
                Logout
              </button>
            </div>
          </div>
        </header>
        <div className="content p-4">
          {renderReport(currentPage)}
        </div>
      </main>

      <Modal
        show={showLogoutConfirm}
        onHide={() => setShowLogoutConfirm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowLogoutConfirm(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogoutConfirm}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Dashboard;

import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import SignIn from './Components/SignIn';
import Dashboard from './Components/dashboard';
import AdminDashboard from './Components/AdminDashboard';
import axios from 'axios';
import { API_BASE_URL } from './auth/api';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [userRoles, setUserRoles] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      const storedUsername = localStorage.getItem('username');
      
      if (token && storedUsername) {
        try {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          
          const response = await axios.get(`${API_BASE_URL}/users/${storedUsername}/roles`);
          setUserRoles(response.data);
          setIsAdmin(response.data.includes('all'));
          setUsername(storedUsername);
          setIsLoggedIn(true);
        } catch (error) {
          console.error('Auth error:', error);
          handleLogout();
        }
      }
      setIsLoading(false);
    };

    initializeAuth();
  }, []);

  const fetchUserRoles = async (username) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users/${username}/roles`);
      setUserRoles(response.data);
      setIsAdmin(response.data.includes('all'));
    } catch (error) {
      console.error('Error fetching user roles:', error);
    }
  };

  const checkLogin = async (inputUsername, password) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/login`, {
        username: inputUsername,
        password: password
      });
      
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', inputUsername);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        setIsLoggedIn(true);
        setUsername(inputUsername);
        await fetchUserRoles(inputUsername);
        return true;
      }
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
    return false;
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    setUserRoles([]);
    setIsAdmin(false);
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    delete axios.defaults.headers.common['Authorization'];
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <SignIn checkLogin={checkLogin} />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path="/" 
          element={
            <Dashboard 
              onLogout={handleLogout} 
              username={username} 
              userRoles={userRoles}
              isAdmin={isAdmin}
            />
          } 
        />
        <Route 
          path="/admin" 
          element={
            isAdmin ? (
              <AdminDashboard 
                onLogout={handleLogout} 
                username={username}
              />
            ) : (
              <Navigate to="/" replace />
            )
          } 
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
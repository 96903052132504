import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../auth/api';
import {
  Container,
  Tabs,
  Tab,
  Form,
  Button,
  Table,
  Modal,
  Navbar,
  Nav
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const AVAILABLE_ICONS = [
  { value: 'fa-folder', label: '📁 Folder' },
  { value: 'fa-file', label: '📄 File' },
 
];

function AdminDashboard({ onLogout, username }) {
  const [key, setKey] = useState('menu');
  const [menuItems, setMenuItems] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  
  const [newItem, setNewItem] = useState({
    name: '',
    type: 'folder',
    path: '',
    parentId: null,
    order: 0,
    icon: '',
    roles: [],
    report: {
      type: 'iframe',
      url: '',
      isDisabled: false
    }
  });

  const [newRole, setNewRole] = useState({
    name: '',
    description: ''
  });

  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    roles: []
  });

  const [editRole, setEditRole] = useState(null);
  const [editUser, setEditUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const buildMenuTree = (items, parentId = null, level = 0) => {
    const result = [];
    const children = items.filter(item => 
      (parentId === null ? !item.parentId : item.parentId === parentId)
    );

    children.sort((a, b) => a.order - b.order);

    children.forEach(item => {
      result.push({ ...item, level });
      const subItems = buildMenuTree(items, item._id, level + 1);
      result.push(...subItems);
    });

    return result;
  };

  const fetchData = async () => {
    try {
      const [menuRes, rolesRes, usersRes] = await Promise.all([
        axios.get(`${API_BASE_URL}/menu`),
        axios.get(`${API_BASE_URL}/roles`),
        axios.get(`${API_BASE_URL}/users`)
      ]);
      
      // Sử dụng buildMenuTree để sắp xếp menu items
      const treeMenuItems = buildMenuTree(menuRes.data);
      setMenuItems(treeMenuItems);
      
      // Sắp xếp roles và users như cũ
      const sortedRoles = rolesRes.data.sort((a, b) => a.name.localeCompare(b.name));
      setRoles(sortedRoles);
      
      const sortedUsers = usersRes.data.sort((a, b) => a.username.localeCompare(b.username));
      setUsers(sortedUsers);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Tạo path cho menu item
      let path = newItem.name.toLowerCase().replace(/\s+/g, '-');
      if (newItem.parentId) {
        const parent = menuItems.find(item => item._id === newItem.parentId);
        if (parent) {
          path = `${parent.path}/${path}`;
        }
      }

      const menuData = {
        ...newItem,
        path: path,
        roles: Array.isArray(newItem.roles) ? newItem.roles : []
      };
      
      if (editItem) {
        await axios.put(`${API_BASE_URL}/menu/${editItem._id}`, menuData);
      } else {
        await axios.post(`${API_BASE_URL}/menu`, menuData);
      }
      fetchData();
      resetForm();
    } catch (error) {
      console.error('Error saving menu item:', error);
    }
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setNewItem(item);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        await axios.delete(`${API_BASE_URL}/menu/${id}`);
        fetchData();
      } catch (error) {
        console.error('Error deleting menu item:', error);
      }
    }
  };

  const resetForm = () => {
    setEditItem(null);
    setNewItem({
      name: '',
      type: 'folder',
      path: '',
      parentId: null,
      order: 0,
      icon: '',
      roles: [],
      report: {
        type: 'iframe',
        url: '',
        isDisabled: false
      }
    });
    setShowModal(false);
  };

  const handleRoleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editRole) {
        await axios.put(`${API_BASE_URL}/roles/${editRole._id}`, newRole);
      } else {
        await axios.post(`${API_BASE_URL}/roles`, newRole);
      }
      fetchData();
      resetRoleForm();
    } catch (error) {
      console.error('Error saving role:', error);
    }
  };

  const handleEditRole = (role) => {
    setEditRole(role);
    setNewRole({
      name: role.name,
      description: role.description
    });
    setShowRoleModal(true);
  };

  const resetRoleForm = () => {
    setEditRole(null);
    setNewRole({ name: '', description: '' });
    setShowRoleModal(false);
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editUser) {
        await axios.put(`${API_BASE_URL}/users/${editUser._id}`, newUser);
        alert('User updated successfully');
      } else {
        await axios.post(`${API_BASE_URL}/users`, newUser);
        alert('User added successfully');
      }
      fetchData();
      resetUserForm();
    } catch (error) {
      console.error('Error saving user:', error);
      alert('Error saving user: ' + error.message);
    }
  };

  const handleEditUser = (user) => {
    setEditUser(user);
    setNewUser({
      username: user.username,
      password: user.password,
      roles: user.roles.map(role => role._id)
    });
    setShowUserModal(true);
  };

  const resetUserForm = () => {
    setEditUser(null);
    setNewUser({ username: '', password: '', roles: [] });
    setShowUserModal(false);
  };

  const handleDeleteRole = async (id) => {
    if (window.confirm('Are you sure you want to delete this role? This may affect users with this role.')) {
      try {
        await axios.delete(`${API_BASE_URL}/roles/${id}`);
        fetchData();
        alert('Role deleted successfully');
      } catch (error) {
        console.error('Error deleting role:', error);
        alert('Error deleting role: ' + error.message);
      }
    }
  };

  const handleDeleteUser = async (id) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        await axios.delete(`${API_BASE_URL}/users/${id}`);
        fetchData();
        alert('User deleted successfully');
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Error deleting user: ' + error.message);
      }
    }
  };

  const handleDeleteMenuItem = async (id) => {
    if (window.confirm('Are you sure you want to delete this menu item? This may affect the menu structure.')) {
      try {
        await axios.delete(`${API_BASE_URL}/menu/${id}`);
        fetchData();
        alert('Menu item deleted successfully');
      } catch (error) {
        console.error('Error deleting menu item:', error);
        alert('Error deleting menu item: ' + error.message);
      }
    }
  };

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>Admin Dashboard</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link>Welcome, {username}</Nav.Link>
          </Nav>
          <Nav>
            <Button 
              variant="outline-light" 
              className="me-2"
              onClick={() => navigate('/')}
            >
              Back to Dashboard
            </Button>
            <Button variant="outline-light" onClick={onLogout}>
              Logout
            </Button>
          </Nav>
        </Container>
      </Navbar>

      <Container className="mt-4">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab eventKey="menu" title="Menu Management">
            <div className="mt-3">
              <Button variant="primary" onClick={() => setShowModal(true)}>
                Add New Menu Item
              </Button>

              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Parent</th>
                    <th>Order</th>
                    <th>Roles</th>
                    <th style={{ width: '150px' }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {menuItems.map(item => (
                    <tr key={item._id}>
                      <td>
                        <div style={{ 
                          marginLeft: `${item.level * 20}px`,
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px'
                        }}>
                          {item.icon && (
                            <span>
                              {AVAILABLE_ICONS.find(icon => icon.value === item.icon)?.label.split(' ')[0]}
                            </span>
                          )}
                          {item.type === 'folder' ? (
                            <span style={{ fontWeight: 500 }}>{item.name}</span>
                          ) : (
                            <span>{item.name}</span>
                          )}
                        </div>
                      </td>
                      <td>{item.type}</td>
                      <td>{menuItems.find(m => m._id === item.parentId)?.name || '-'}</td>
                      <td>{item.order}</td>
                      <td>{Array.isArray(item.roles) ? item.roles.join(', ') : item.roles}</td>
                      <td>
                        <div className="d-flex gap-2">
                          <Button 
                            variant="info" 
                            size="sm"
                            onClick={() => handleEdit(item)}
                          >
                            Edit
                          </Button>
                          <Button 
                            variant="danger" 
                            size="sm"
                            onClick={() => handleDeleteMenuItem(item._id)}
                          >
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Tab>

          <Tab eventKey="roles" title="Roles Management">
            <div className="mt-3">
              <Button variant="primary" onClick={() => setShowRoleModal(true)}>
                Add New Role
              </Button>
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {roles.map(role => (
                    <tr key={role._id}>
                      <td>{role.name}</td>
                      <td>{role.description}</td>
                      <td>
                        <Button variant="info" size="sm" onClick={() => handleEditRole(role)}>Edit</Button>
                        {' '}
                        <Button variant="danger" size="sm" onClick={() => handleDeleteRole(role._id)}>Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Tab>

          <Tab eventKey="users" title="Users Management">
            <div className="mt-3">
              <Button variant="primary" onClick={() => setShowUserModal(true)}>
                Add New User
              </Button>
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Roles</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(user => (
                    <tr key={user._id}>
                      <td>{user.username}</td>
                      <td>
                        {user.roles.map(role => role.name).join(', ')}
                      </td>
                      <td>
                        <Button variant="info" size="sm" onClick={() => handleEditUser(user)}>
                          Edit
                        </Button>
                        {' '}
                        <Button 
                          variant="danger" 
                          size="sm" 
                          onClick={() => handleDeleteUser(user._id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Tab>
        </Tabs>

        <Modal show={showModal} onHide={resetForm}>
          <Modal.Header closeButton>
            <Modal.Title>{editItem ? 'Edit Menu Item' : 'Add New Menu Item'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newItem.name}
                  onChange={e => setNewItem({...newItem, name: e.target.value})}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  value={newItem.type}
                  onChange={e => setNewItem({...newItem, type: e.target.value})}
                  required
                >
                  <option value="folder">Folder</option>
                  <option value="report">Report</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Parent Menu</Form.Label>
                <Form.Select
                  value={newItem.parentId || ''}
                  onChange={e => setNewItem({...newItem, parentId: e.target.value || null})}
                >
                  <option value="">None (Root Level)</option>
                  {menuItems
                    .filter(item => item.type === 'folder' && item._id !== editItem?._id)
                    .map(item => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Order</Form.Label>
                <Form.Control
                  type="number"
                  value={newItem.order}
                  onChange={e => setNewItem({...newItem, order: parseInt(e.target.value)})}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Icon</Form.Label>
                <div className="d-flex flex-wrap gap-2 border rounded p-2" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {AVAILABLE_ICONS.map((icon) => (
                    <Button
                      key={icon.value}
                      variant={newItem.icon === icon.value ? 'primary' : 'outline-secondary'}
                      onClick={() => setNewItem({...newItem, icon: icon.value})}
                      className="d-flex align-items-center gap-2"
                      style={{ minWidth: '120px' }}
                    >
                      <span>{icon.label}</span>
                    </Button>
                  ))}
                </div>
              </Form.Group>

              {newItem.type === 'report' && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>Report Type</Form.Label>
                    <Form.Select
                      value={newItem.report.type}
                      onChange={e => setNewItem({
                        ...newItem,
                        report: {...newItem.report, type: e.target.value}
                      })}
                    >
                      <option value="iframe">iFrame</option>
                      <option value="powerbi">Power BI</option>
                      <option value="googlesheet">Google Sheet</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Report URL</Form.Label>
                    <Form.Control
                      type="text"
                      value={newItem.report.url}
                      onChange={e => setNewItem({
                        ...newItem,
                        report: {...newItem.report, url: e.target.value}
                      })}
                      required={newItem.type === 'report'}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check
                      type="checkbox"
                      label="Disabled"
                      checked={newItem.report.isDisabled}
                      onChange={e => setNewItem({
                        ...newItem,
                        report: {...newItem.report, isDisabled: e.target.checked}
                      })}
                    />
                  </Form.Group>
                </>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Roles</Form.Label>
                <Form.Select
                  multiple
                  value={newItem.roles}
                  onChange={e => setNewItem({
                    ...newItem,
                    roles: Array.from(e.target.selectedOptions, option => option.value)
                  })}
                >
                  {roles.map(role => (
                    <option key={role._id} value={role.name}>{role.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Button type="submit">
                {editItem ? 'Update' : 'Add'} Menu Item
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showRoleModal} onHide={resetRoleForm}>
          <Modal.Header closeButton>
            <Modal.Title>{editRole ? 'Edit Role' : 'Add New Role'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleRoleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Role Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newRole.name}
                  onChange={e => setNewRole({...newRole, name: e.target.value})}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  value={newRole.description}
                  onChange={e => setNewRole({...newRole, description: e.target.value})}
                />
              </Form.Group>

              <Button type="submit">
                {editRole ? 'Update' : 'Add'} Role
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showUserModal} onHide={resetUserForm}>
          <Modal.Header closeButton>
            <Modal.Title>{editUser ? 'Edit User' : 'Add New User'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleUserSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  value={newUser.username}
                  onChange={e => setNewUser({...newUser, username: e.target.value})}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="text"
                  value={newUser.password}
                  onChange={e => setNewUser({...newUser, password: e.target.value})}
                  required={!editUser}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Roles</Form.Label>
                <Form.Select
                  multiple
                  value={newUser.roles}
                  onChange={e => setNewUser({
                    ...newUser,
                    roles: Array.from(e.target.selectedOptions, option => option.value)
                  })}
                >
                  {roles.map(role => (
                    <option key={role._id} value={role._id}>
                      {role.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Button type="submit" className="me-2">
                {editUser ? 'Update' : 'Add'} User
              </Button>
              <Button variant="secondary" onClick={resetUserForm}>
                Cancel
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
}

export default AdminDashboard; 